import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NbActionsModule, NbButtonModule, NbCardModule, NbIconModule, NbListModule, NbRadioModule, NbSelectModule, NbSpinnerModule, NbTabsetModule, NbUserModule } from '@nebular/theme';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartModule } from 'angular2-chartjs';
import { NgxEchartsModule } from 'ngx-echarts';
import { TemperaturaService } from '../service/temperatura.service';
import { EchartsBarAnimationConsumoComponent } from './charts/echarts/card-consumo/echarts-bar-animation-consumo.component';
import { EchartsLineConsumoPrevistoXRealizadoComponent } from './charts/echarts/card-consumo/echarts-line-consumo-previsto-realizado.component';
import { EchartsBarAnimationDemandaComponent } from './charts/echarts/card-demanda/echarts-bar-animation-demanda.component';
import { EchartsBarPotenciaComponent } from './charts/echarts/card-dmr/echarts-bar-DMCR.component';
import { EchartsBarEnergiaComponent } from './charts/echarts/card-energia/echarts-bar-energia.component';
import { EchartsBarAnimationFatorPotenciaComponent } from './charts/echarts/card-fator-potencia/echarts-bar-animation-fator-potencia.component';
import { EchartsBarAnimationFaturaComponent } from './charts/echarts/card-fatura/echarts-bar-animation-fatura.component';
import { EchartsPieFaturadoComponent } from './charts/echarts/card-fatura/echarts-pie-faturado.component';
import { EchartsBarPotencialEconomiaComponent } from './charts/echarts/card-potencial-economia/echarts-bar-potencial-economia.component';
import { EchartsLineTemperatureComponent } from './charts/echarts/card-temperatura/echarts-line.component-temperature';
import { EchartsPieReportCo2Component } from './charts/echarts/report/co2/echarts-pie-report-co2-carac.component';
import { EchartsPieReportCo2TypePerUnitComponent } from './charts/echarts/report/co2/echarts-pie-report-co2-unit-type.component';
import { EchartsBarReportComparisonSitesComponent } from './charts/echarts/report/comparison-sites/echarts-bar-report-comparison-sites.component';
import { EchartsBarHistoryConsumptionRsComponent } from './charts/echarts/report/history-consumption/echarts-bar-history-consumption-rs.component';
import { EchartsBarHistoryConsumptionComponent } from './charts/echarts/report/history-consumption/echarts-bar-history-consumption.component';
import { EchartsBarReportTotalCostComponent } from './charts/echarts/report/total-cost/echarts-bar-report-total-cost.component';
import { EchartsPieReportTotalCostComponent } from './charts/echarts/report/total-cost/echarts-pie-report-total-cost.component';
import { DatepickerAnualComponent } from './component/datepicker-anual/datepicker-anual.component';
import { DatepickerDiarioComponent } from './component/datepicker-diario/datepicker-diario.component';
import { DatepickerMensalComponent } from './component/datepicker-mensal/datepicker-mensal.component';
import { SpinnerColorComponent } from './extra-components/spinner/spinner-color/spinner-color.component';
import { SpinnerInButtonsComponent } from './extra-components/spinner/spinner-in-buttons/spinner-in-buttons.component';
import { SpinnerInTabsComponent } from './extra-components/spinner/spinner-in-tabs/spinner-in-tabs.component';
import { SpinnerSizesComponent } from './extra-components/spinner/spinner-sizes/spinner-sizes.component';
import { SpinnerComponent } from './extra-components/spinner/spinner.component';
import { LeafletComponent } from './maps/leaflet/leaflet.component';
import { DialogSimulatorPromptComponent } from './modal-overlays/dialog/dialog-simulator-prompt/dialog-simulator-prompt.component';

const components = [
    LeafletComponent,
    DatepickerMensalComponent,
    DatepickerAnualComponent,
    DatepickerDiarioComponent,
];

const dialogComponents = [
    DialogSimulatorPromptComponent,
];

const spinnersComponents = [
    SpinnerComponent,
    SpinnerColorComponent,
    SpinnerSizesComponent,
    SpinnerInButtonsComponent,
    SpinnerInTabsComponent,
];

const chartsComponents = [
    EchartsPieReportCo2Component,
    EchartsBarPotencialEconomiaComponent,
    EchartsBarEnergiaComponent,
    EchartsBarReportComparisonSitesComponent,
    EchartsPieReportCo2TypePerUnitComponent,
    EchartsPieReportTotalCostComponent,
    EchartsBarReportTotalCostComponent,
    EchartsLineConsumoPrevistoXRealizadoComponent,
    EchartsBarPotenciaComponent,
    EchartsPieFaturadoComponent,
    EchartsBarAnimationFaturaComponent,
    EchartsBarAnimationDemandaComponent,
    EchartsBarAnimationConsumoComponent,
    EchartsBarAnimationFatorPotenciaComponent,
    EchartsLineTemperatureComponent,
    EchartsBarHistoryConsumptionComponent,
    EchartsBarHistoryConsumptionRsComponent,
];

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        CommonModule,
        NbCardModule,
        NbUserModule,
        NbButtonModule,
        NbTabsetModule,
        NbActionsModule,
        NbRadioModule,
        NbSelectModule,
        NbListModule,
        LeafletModule,
        NbIconModule,
        NbButtonModule,
        ReactiveFormsModule,
        NgxEchartsModule,
        NgxChartsModule,
        ChartModule,
        MatInputModule,
        NbSpinnerModule,
        MatDatepickerModule,
    ],
    declarations: [
        components,
        chartsComponents,
        dialogComponents,
        spinnersComponents,
    ],
    entryComponents: [
        dialogComponents,
    ],
    exports: [
        components,
        chartsComponents,
        dialogComponents,
        spinnersComponents,
    ],
    providers: [
        TemperaturaService,
    ],
})
export class ComponentModule { }
