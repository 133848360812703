import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { Temperatura } from '../../../../model/temperatura.model';

@Component({
    selector: 'ngx-echarts-bar-animation-demanda',
    template: `
    <div echarts [options]="options" class="echart" ></div>
  `,
})

export class EchartsBarAnimationDemandaComponent implements AfterViewInit, OnChanges {
    @Input() dataDemanda: any;
    @Input() dataDemandaContratada: any;

    options: any = {};
    themeSubscription: any;
    dadosDeTemperatura: Temperatura[];

    dataTemperatura = [];
    xAxisData = []; // [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
    dataForaPonta = []; // [17.65, 0, 0, 0, 19, 21, 22, 23, 25, 25, 26, 24, 27, 21, 29, 26, 25, 27, 29, 27, 23, 27, 27, 28];
    dataPonta = []; // [0, 12, 8, 10, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    dataContratada = []; // [30, 15, 15, 15, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30];
    demandaPonta = 0;
    demandaForaPonta = 0;

    constructor(private theme: NbThemeService) { }

    ngAfterViewInit() {
        if (this.dataDemandaContratada) {
            this.demandaPonta = this.dataDemandaContratada.demandaContratadaPonta;
            this.demandaForaPonta = this.dataDemandaContratada.demandaContratadaForaPonta;
        }
        if (this.dataDemanda) {
            this.limpaDados();

            let arrayOfValues = this.dataDemanda;
            arrayOfValues.forEach(item => {
                if (item.postoTarifario === 'PONTA') {
                    this.xAxisData.push((new Date(item.hora)).toLocaleString());
                    this.dataPonta.push(item.demanda);
                    this.dataForaPonta.push(0);
                    this.dataContratada.push(this.demandaPonta);
                } else if (item.postoTarifario === 'FORA_PONTA') {
                    this.xAxisData.push((new Date(item.hora)).toLocaleString());
                    this.dataPonta.push(0);
                    this.dataForaPonta.push(item.demanda);
                    this.dataContratada.push(this.demandaForaPonta);
                }
            });
            this.loadDataToChart();
        }
    }

    ngOnChanges(changes: import('@angular/core').SimpleChanges): void {

        if (this.dataDemandaContratada) {
            this.demandaPonta = this.dataDemandaContratada.demandaContratadaPonta;
            this.demandaForaPonta = this.dataDemandaContratada.demandaContratadaForaPonta;
        }
        if (this.dataDemanda) {
            this.limpaDados();

            let arrayOfValues = this.dataDemanda;
            arrayOfValues.forEach(item => {
                if (item.postoTarifario === 'PONTA') {
                    this.xAxisData.push((new Date(item.hora)).toLocaleString());
                    this.dataPonta.push(item.demanda);
                    this.dataForaPonta.push(0);
                    this.dataContratada.push(this.demandaPonta);
                } else if (item.postoTarifario === 'FORA_PONTA') {
                    this.xAxisData.push((new Date(item.hora)).toLocaleString());
                    this.dataPonta.push(0);
                    this.dataForaPonta.push(item.demanda);
                    this.dataContratada.push(this.demandaForaPonta);
                }
            });
            this.loadDataToChart();
        }
    }

    loadDataToChart() {

        this.themeSubscription = this.theme.getJsTheme().subscribe(config => {
            const colors: any = config.variables;
            const echarts: any = config.variables.echarts;

            this.options = {
                backgroundColor: echarts.bg,
                color: [colors.primaryLight, colors.infoLight],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                    },
                    formatter:
                        function (params) {
                            let texto = '';
                            params.forEach(dados => {
                                if (dados.data !== 0) {
                                    switch (dados.seriesName) {
                                        case 'Fora Ponta':
                                            texto += dados.seriesName + ': ' + (dados.data).toLocaleString('pt-br') + ' kW ' + '<br>';
                                            break;
                                        case 'Ponta':
                                            texto += dados.seriesName + ': ' + (dados.data).toLocaleString('pt-br') + ' kW ' + '<br>';
                                            break;
                                        case 'Contratada':
                                            texto += dados.seriesName + ': ' + (dados.data).toLocaleString('pt-br') + ' kW ' + '<br>';
                                            break;
                                        case 'Temperatura':
                                            texto += dados.seriesName + ': ' + (dados.data).toLocaleString('pt-br') + ' °C ' + '<br>';
                                            break;

                                        default:
                                            break;
                                    }

                                }
                            });

                            // texto += 'Hora: ' + (new Date(params[0].axisValueLabel)).toLocaleString();
                            texto += 'Hora: ' + params[0].axisValueLabel;

                            return texto;
                        }
                    , position: function (pos, params, dom, rect, size) {

                        if (size.viewSize[0] < 500) {
                            if (+(pos[0] > size.viewSize[0] * 0.2)) {
                                return [size.viewSize[0] * 0.2, pos[1]];
                            }
                            return [pos[0], pos[1]];
                        } else {
                            if (+(pos[0] > size.viewSize[0] * 0.8)) {
                                return [size.viewSize[0] * 0.8, pos[1]];
                            }
                            return [pos[0], pos[1]];
                        }
                    },

                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                legend: {
                    data: ['Fora Ponta', 'Ponta', 'Contratada', 'Temperatura'],
                    align: 'left',
                    type: 'scroll',
                    textStyle: {
                        color: echarts.textColor,
                    },
                },
                xAxis: [
                    {
                        type: 'category',
                        splitLine: { show: false },
                        data: this.xAxisData,
                        silent: false,
                        axisTick: {
                            alignWithLabel: true,
                        },
                        axisLine: {
                            lineStyle: {
                                color: echarts.axisLineColor,
                            },
                        },
                        axisLabel: {
                            textStyle: {
                                color: echarts.textColor,
                            },
                            margin: 15,
                        },
                    },
                ],
                yAxis: [

                    {
                        type: 'value',
                        name: '[ kW ]',
                        min: 0,
                        // ax: 55,
                        position: 'left',
                        offset: 0,
                        axisLine: {
                            lineStyle: {
                                color: 'blue',
                            },
                        },
                        axisLabel: {
                            formatter: function (params) {
                                return (params).toLocaleString('pt-br');
                            },
                        },
                    },
                    //@todo Remover linhas comentadas
                    // {
                    //   type: 'value',
                    //   name: 'Temperatura',
                    //   min: 0,
                    //   //max: 50,
                    //   offset: 10,
                    //   position: 'right',
                    //   axisLine: {
                    //     lineStyle: {
                    //       color: 'orange'
                    //     }
                    //   },
                    //   axisLabel: {
                    //     formatter: '{value} °C'
                    //   }
                    // }
                ],
                series: [
                    {
                        name: 'Fora Ponta',
                        type: 'bar',
                        data: this.dataForaPonta,
                        yAxisIndex: 0,
                        stack: 'one',
                        itemStyle: { color: 'green' },
                        animationDelay: idx => idx * 10,
                    },
                    {
                        name: 'Ponta',
                        type: 'bar',
                        stack: 'one',
                        yAxisIndex: 0,
                        data: this.dataPonta,
                        //itemStyle: { color: 'red' },
                        animationDelay: idx => idx * 10 + 100,
                    },
                    {
                        name: 'Contratada',
                        type: 'line',
                        itemStyle: { color: 'red' },
                        yAxisIndex: 0,
                        data: this.dataContratada,
                    },
                    //@todo Remover linhas comentadas
                    // {
                    //   name: 'Temperatura',
                    //   type: 'line',
                    //   itemStyle: { color: 'orange' },
                    //   yAxisIndex: 1,
                    //   data: this.dataTemperatura
                    // }
                ],
                animationEasing: 'elasticOut',
                animationDelayUpdate: idx => idx * 5,
            };
        });
    }

    limpaDados() {
        this.dataTemperatura = [];
        this.xAxisData = []; // [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
        this.dataForaPonta = []; // [17.65, 0, 0, 0, 19, 21, 22, 23, 25, 25, 26, 24, 27, 21, 29, 26, 25, 27, 29, 27, 23, 27, 27, 28];
        this.dataPonta = []; // [0, 12, 8, 10, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.dataContratada = []; // [30, 15, 15, 15, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30]
    }

}
