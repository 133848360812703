import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { Fatura } from '../../../../model/fatura.model';

@Component({
    selector: 'ngx-echarts-pie-faturado',
    template: `
    <div echarts [options]="options" class="echart"></div>
  `,
})
export class EchartsPieFaturadoComponent implements AfterViewInit, OnChanges {

    @Input() dataChart: any;
    @Input() histInputBill: Fatura[];

    data1 = ['Consumo', 'Demanda', 'Impostos', 'Penalidades', 'Restituições'];
    data2 = [
        { value: 0, name: 'Consumo' },
        { value: 0, name: 'Demanda' },
        { value: 0, name: 'Impostos' },
        { value: 0, name: 'Penalidades' },
        { value: 0, name: 'Restituições' },
    ];

    options: any = {};
    themeSubscription: any;

    constructor(private theme: NbThemeService) {
    }

    ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
        this.processData();
        this.carregaGrafico();
    }

    ngAfterViewInit() {
        this.processData();
        this.carregaGrafico();
    }

    processData() {
        if (this.dataChart) {
            this.data2 = this.data2 = [
                {
                    value: this.dataChart.consumoEnergiaAtivaPontaValor + this.dataChart.consumoEnergiaAtivaForaPontaValor,
                    name: 'Consumo',
                },
                {
                    value: this.dataChart.demandaForaPontaValorComICMS + this.dataChart.demandaPontaValorComICMS,
                    name: 'Demanda',
                },
                {
                    value: this.dataChart.valorPIS + this.dataChart.valorCOFINS + this.dataChart.valorICMS,
                    name: 'Impostos',
                },
                {
                    value: this.dataChart.ultrapassagemDemandaPontaValor + this.dataChart.ultrapassagemDemandaForaPontaValor,
                    name: 'Penalidades',
                },
                {
                    value: this.dataChart.ressarcimentos + this.dataChart.descontos,
                    name: 'Restituições',
                },
            ];
        } else if (this.histInputBill) {// @todo conferir essa logica
            let mes = (new Date()).getMonth();

            if (mes !== 0)// janeiro
                this.histInputBill.forEach(item => {
                    if ((new Date(item.dataReferencia)).getMonth() === mes - 1) {
                        this.data2 = [
                            {
                                value: item.consumoEnergiaAtivaPontaValor + item.consumoEnergiaAtivaForaPontaValor,
                                name: 'Consumo',
                            },
                            {
                                value: item.demandaForaPontaValorComICMS + item.demandaPontaValorComICMS,
                                name: 'Demanda',
                            },
                            {
                                value: item.valorPIS + item.valorCOFINS + item.valorICMS,
                                name: 'Impostos',
                            },
                            {
                                value: item.ultrapassagemDemandaPontaValor + item.ultrapassagemDemandaForaPontaValor,
                                name: 'Penalidades',
                            },
                            {
                                value: item.ressarcimentos + item.descontos,
                                name: 'Restituições',
                            },
                        ];

                    }
                });
        }
    }

    carregaGrafico() {
        this.themeSubscription = this.theme.getJsTheme().subscribe(config => {

            const colors = config.variables;
            const echarts: any = config.variables.echarts;


            this.options = {
                backgroundColor: echarts.bg,
                color: [colors.warningLight, colors.infoLight, colors.dangerLight, colors.successLight, colors.primaryLight],
                responsive: true,
                tooltip: {
                    trigger: 'item',
                    formatter:
                        function (params) {
                            let value = params.data.value.toFixed(2).toString();
                            value = value.replace(/\./g, ',');
                            const msg = params.name + ': <br> R$ ' + (value).toLocaleString('pt-br') + ' ( ' + (params.percent).toLocaleString('pt-br') + ' %)';
                            return msg;
                        },

                },
                legend: {
                    orient: 'vertical',
                    type: 'scroll',
                    left: 'right',
                    data: this.data1,
                    textStyle: {
                        color: echarts.textColor,
                    },
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                series: [
                    {
                        name: 'Valores faturados',
                        type: 'pie',
                        radius: '55%',
                        center: ['50%', '60%'],
                        data: this.data2,
                        itemStyle: {
                            emphasis: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: echarts.itemHoverShadowColor,
                            },
                        },
                        label: {
                            normal: {
                                textStyle: {
                                    color: echarts.textColor,
                                },
                            },
                        },
                        labelLine: {
                            normal: {
                                lineStyle: {
                                    color: echarts.axisLineColor,
                                },
                            },
                        },
                    },
                ],
            };
        });
    }


}
