import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'DD MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'DD MMMM YYYY',
  },
};

@Component({
  selector: 'ngx-datepicker-diario',
  templateUrl: './datepicker-diario.component.html',
  styleUrls: ['./datepicker-diario.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DatepickerDiarioComponent implements AfterViewInit, OnChanges {

  date = new FormControl(moment());

  @Input() dateInput: Date;
  @Input() title: string = 'Data';
  @Output() dateEvent = new EventEmitter<any>();

  constructor(private _adapter: DateAdapter<any>, private datePipe: DatePipe) {
    this._adapter.setLocale('pt-br');
  }

  ngAfterViewInit(): void {
    if (this.dateInput)
      this.date = new FormControl(new Date(this.dateInput).toISOString());
  }

  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    if (this.dateInput)
      this.date = new FormControl(new Date(this.dateInput).toISOString());
  }


  formatarData(date) {
    return this.datePipe.transform(date, 'dd-MM-yyyy');
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    //console.log(`${type}: ${event.value}`);
    this.dateEvent.emit(this.formatarData(event.value));
  }



}
