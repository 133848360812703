import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()

export class ServiceMaster {

    private static hdrs;
    public static headers;

    private static hdrs_upload;
    public static headers_upload;
    // public static readonly baseURL = 'http://teste.telefonicavee.com.br:10001';
    // public static readonly baseURL = 'http://10.10.10.20:10000';
    // public static readonly baseURL = 'http://177.66.65.106:10000';
    // public static readonly baseURL = 'https://teste.telefonica.ativasolucoes.com.br';
    public static readonly baseURL = 'https://telefonica.ativasolucoes.com.br';
    // public static readonly baseURL = 'http://localhost:10000';

    static token: any;
    constructor() { }

    static setNewTokenInHeaders(newToken: string) {

        this.token = newToken;
        ServiceMaster.hdrs = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + newToken,
        };

        ServiceMaster.headers = new HttpHeaders(ServiceMaster.hdrs);
    }

    static getHeaders() {

        if (ServiceMaster.headers) {
            return ServiceMaster.headers;
        } else {
            if (this.token === undefined) {
                this.token = sessionStorage.getItem('tokenTelefonica');
            }

            ServiceMaster.hdrs = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.token,
            };

            ServiceMaster.headers = new HttpHeaders(ServiceMaster.hdrs);
            return ServiceMaster.headers;
        }
    }

    static getHeadersUpload() {

        if (ServiceMaster.headers_upload) {
            return ServiceMaster.headers_upload;
        } else {
            if (this.token === undefined) {
                this.token = sessionStorage.getItem('tokenTelefonica');
            }

            ServiceMaster.hdrs_upload = {
                'Authorization': 'Bearer ' + this.token,
            };

            ServiceMaster.headers_upload = new HttpHeaders(ServiceMaster.hdrs_upload);
            return ServiceMaster.headers_upload;
        }
    }

}
