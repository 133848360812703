import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { MESES } from '../../../../util/legendas_grafico.const';

@Component({
    selector: 'ngx-echarts-bar-animation-fatura',
    template: `
    <div echarts [options]="options" class="echart"></div>
  `,
})
export class EchartsBarAnimationFaturaComponent implements AfterViewInit, OnChanges {

    @Input() dataChart: any;
    @Input() meses: any;

    options: any = {};
    themeSubscription: any;

    data1 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    constructor(private theme: NbThemeService) {
    }

    ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
        if (this.dataChart) {
            this.data1 = this.dataChart;
        }
        this.carregaGrafico();
    }

    ngAfterViewInit() {
        if (this.dataChart) {
            this.data1 = this.dataChart;
        }

        this.carregaGrafico();
    }

    carregaGrafico() {
        this.themeSubscription = this.theme.getJsTheme().subscribe(config => {
            const xAxisData = (this.meses) ? (this.meses) : MESES;

            const colors: any = config.variables;
            const echarts: any = config.variables.echarts;

            let emphasisStyle = {
                itemStyle: {
                    barBorderWidth: 1,
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowOffsetY: 0,
                    shadowColor: 'rgba(0,0,0,0.5)',
                },
            };

            this.options = {
                backgroundColor: echarts.bg,
                color: [colors.primaryLight, colors.infoLight],
                tooltip: {
                    trigger: 'axis',
                    formatter:
                        function (params) {
                            let texto = '';
                            params.forEach(dados => {
                                texto += dados.seriesName + ': R$ ' + (dados.data).toLocaleString('pt-br') + '<br>';
                            });
                            // adding X label
                            texto += 'Mês: ' + params[0].axisValueLabel;
                            return texto;
                        }
                    , position: function (pos, params, dom, rect, size) {

                        if (size.viewSize[0] < 500) {
                            if (+(pos[0] > size.viewSize[0] * 0.2)) {
                                return [size.viewSize[0] * 0.2, pos[1]];
                            }
                            return [pos[0], pos[1]];
                        } else {
                            if (+(pos[0] > size.viewSize[0] * 0.7)) {
                                return [size.viewSize[0] * 0.7, pos[1]];
                            }
                            return [pos[0], pos[1]];
                        }
                    },

                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                legend: {
                    data: ['Fatura total'],
                    align: 'left',
                    type: 'scroll',
                    textStyle: {
                        color: echarts.textColor,
                    },
                },
                xAxis: [
                    {
                        type: 'category',
                        //name: '[ Mês ]',
                        splitLine: { show: false },
                        data: xAxisData,
                        silent: false,
                        axisTick: {
                            alignWithLabel: true,
                        },
                        axisLine: {
                            lineStyle: {
                                color: echarts.axisLineColor,
                            },
                        },
                        axisLabel: {
                            textStyle: {
                                color: echarts.textColor,
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        name: '[ R$ ]',
                        axisLine: {
                            lineStyle: {
                                color: echarts.axisLineColor,
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: echarts.splitLineColor,
                            },
                        },
                        axisLabel: {
                            textStyle: {
                                color: echarts.textColor,
                            },
                            formatter: function (params) {
                                return (params).toLocaleString('pt-br');
                            },
                        },
                    },
                ],
                series: [
                    {
                        name: 'Fatura total',
                        type: 'bar',
                        data: this.data1,
                        stack: 'two',
                        emphasis: emphasisStyle,
                        animationDelay: idx => idx * 10,
                    },

                ],
                animationEasing: 'elasticOut',
                animationDelayUpdate: idx => idx * 5,
            };

        });
    }

}
