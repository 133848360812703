import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NbThemeService, NbToastrService } from '@nebular/theme';
import { EconomyPotential } from '../../../../model/economy_potential.model';
import { EconomyPotentialService } from '../../../../service/economy_potential.service';
import { AlertMsg } from '../../../../util/alert/alert.util';

@Component({
    selector: 'ngx-echarts-bar-potencial-economia',
    template: `
    <div echarts [options]="options" class="echart" style="margin-left:15px"></div>
  `,
})
export class EchartsBarPotencialEconomiaComponent implements OnInit {
    options: any = {};
    themeSubscription: any;

    xAxisData = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
    dataDemandaConsumidaPonta = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; // [17.65, 19, 16, 17, 19, 21, 22, 23, 25, 25, 26, 24, 27, 21, 29, 26, 25, 27, 29, 27, 23, 27, 27, 28];
    dataDemandaConsumidaForaPonta = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; // [17.65, 19, 16, 17, 19, 21, 22, 23, 25, 25, 26, 24, 27, 21, 29, 26, 25, 27, 29, 27, 23, 27, 27, 28];
    dataFP = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; // [0.93, 0.95, 0.97, 0.96, 0.91, 0.90, 0.95, 0.98, 0.96, 0.87, 0.89, 0.95, 0.93, 0.98, 0.96, 0.96, 0.92, 0.91, 0.90, 0.93, 0.98, 0.99, 0.91, 0.92];
    dataDemandaContratada = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; // [30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30]

    equipamentoId = '';
    economyPotential: EconomyPotential;

    constructor(private theme: NbThemeService, datePipe: DatePipe, private economyPotentialService: EconomyPotentialService, private route: ActivatedRoute,
        private toastrService: NbToastrService) {
        //pega parametro da url
        this.route.queryParams.subscribe(dados => {
            this.equipamentoId = dados.equipamentID;
        });

    }

    ngOnInit() {
        this.economyPotentialService.getEconomyPotentialTodayByEquipamentId(this.equipamentoId).subscribe(data => {
            this.economyPotential = <EconomyPotential>data.body;

            this.economyPotential.hourlyData.forEach(item => {
                let hora = parseInt(item.hora, 10);

                if (item.postoTarifario === 'PONTA') {
                    this.dataFP[hora] = item.fatorPotencia;
                    this.dataDemandaConsumidaPonta[hora] = item.energiaAtiva;
                    this.dataDemandaContratada[hora] = this.economyPotential.contractedDemand.demandaContratadaPonta;
                } else if (item.postoTarifario === 'FORA_PONTA') {
                    this.dataFP[hora] = item.fatorPotencia;
                    this.dataDemandaConsumidaForaPonta[hora] = item.energiaAtiva;
                    this.dataDemandaContratada[hora] = this.economyPotential.contractedDemand.demandaContratadaForaPonta;
                }
            });

            this.carregaGrafico();
        }, error => {
            AlertMsg.toastResponse(error, this);
        });
    }

    carregaGrafico() {
        this.themeSubscription = this.theme.getJsTheme().subscribe(config => {

            const colors: any = config.variables;
            const echarts: any = config.variables.echarts;
            // '{a0}: {c0} kW <br> {a1}: {c1} kw <br> {a2}: {c2} kW <br> {a3}: {c3} °C'
            this.options = {
                backgroundColor: echarts.bg,
                color: [colors.primaryLight, colors.infoLight],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                    },
                    formatter:
                        function (params) {
                            let texto = '';
                            params.forEach(dados => {
                                if (dados.data !== 0) {
                                    switch (dados.seriesName) {
                                        case 'Fator de Potência':
                                            texto += dados.seriesName + ': ' + dados.data + '<br>';
                                            break;
                                        case 'Demanda Contratada':
                                            texto += dados.seriesName + ': ' + dados.data + ' kW ' + '<br>';
                                            break;
                                        case 'Demanda Consumida':
                                            texto += dados.seriesName + ': ' + dados.data + ' kW ' + '<br>';
                                            break;
                                        default:
                                            break;
                                    }
                                }
                            });

                            // adding X label
                            texto += 'Hora: ' + params[0].axisValueLabel + ':00';
                            return texto;
                        },
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                /* toolbox: {
                  feature: {
                    dataView: { show: true, readOnly: false },
                    restore: { show: true },
                    saveAsImage: { show: true }
                  }
                }, */

                legend: {
                    data: ['Demanda Consumida Ponta', 'Demanda Consumida Fora Ponta', 'Demanda Contratada', 'Fator de Potência'],
                    type: 'scroll',
                    textStyle: {
                        color: echarts.textColor,
                    },
                },
                xAxis: [
                    {
                        type: 'category',
                        splitLine: { show: false },
                        data: this.xAxisData,
                        silent: false,
                        axisTick: {
                            alignWithLabel: true,
                        },
                        axisLine: {
                            lineStyle: {
                                color: echarts.axisLineColor,
                            },
                        },
                        axisLabel: {
                            textStyle: {
                                color: echarts.textColor,
                            },
                            margin: 15,
                        },
                    },
                ],
                yAxis: [

                    {
                        type: 'value',
                        name: 'Demanda Consumida [kW]',
                        position: 'left',
                        offset: 0,
                        axisLine: {
                            lineStyle: {
                                color: 'blue',
                            },
                        },
                        axisLabel: {
                            formatter: '{value} kW',
                        },
                    },
                    {
                        type: 'value',
                        name: 'Fator de Potência',
                        min: 0,
                        max: 1,
                        offset: 1,
                        position: 'right',
                        axisLine: {
                            lineStyle: {
                                color: 'orange',
                            },
                        },
                        axisLabel: {
                            formatter: function (params) {
                                return (params).toLocaleString('pt-br');
                            },
                        },
                    },
                ],
                series: [
                    {
                        name: 'Demanda Consumida Ponta',
                        type: 'bar',
                        data: this.dataDemandaConsumidaPonta,
                        yAxisIndex: 0,
                        animationDelay: idx => idx * 10,
                        stack: 'two',
                        //itemStyle: { color: 'red' },
                    },
                    {
                        name: 'Demanda Consumida Fora Ponta',
                        type: 'bar',
                        data: this.dataDemandaConsumidaForaPonta,
                        yAxisIndex: 0,
                        animationDelay: idx => idx * 10,
                        stack: 'two',
                        itemStyle: { color: 'green' },
                    },
                    {
                        name: 'Fator de Potência',
                        type: 'line',
                        data: this.dataFP,
                        yAxisIndex: 1,
                        itemStyle: { color: 'orange' },
                        animationDelay: idx => idx * 10 + 100,
                    },
                    {
                        name: 'Demanda Contratada',
                        type: 'line',
                        yAxisIndex: 0,
                        itemStyle: { color: 'red' },
                        data: this.dataDemandaContratada,
                    },
                ],
                animationEasing: 'elasticOut',
                animationDelayUpdate: idx => idx * 5,
            };

        });
    }

    showToast(status, message) {
        // status: sucess, info, warning, danger
        const position: any = 'top-right';
        this.toastrService.show(
            '', // submensagem
            `${message}`,
            { position, status, destroyByClick: true });
    }

}
