import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { DemandaRegistrada } from '../../../../model/demanda_registrada.model';
import { HourlyData } from '../../../../model/hourly_data.model';

@Component({
    selector: 'ngx-echarts-bar-DMCR',
    template: `
    <div echarts [options]="options" class="echart" style="margin-left:15px"></div>
  `,
})
export class EchartsBarPotenciaComponent implements OnChanges, AfterViewInit {
    @Input() dataDemandaRegistrada: DemandaRegistrada;
    @Input() dataHourlyData: HourlyData[];

    options: any = {};
    themeSubscription: any;

    xData = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
    yDataDMCRPonta = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    yDataDMCRForaPonta = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    yDataDemandaRegistradaPonta = []; // [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    yDataDemandaRegistradaForaPonta = []; // [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    constructor(private theme: NbThemeService, datePipe: DatePipe) { }

    ngAfterViewInit() {

        let demanda = this.dataDemandaRegistrada;
        let arrayOfValues = this.dataHourlyData;

        if (arrayOfValues && demanda) {
            this.limpaDados();

            for (let i = 0; i < 24; i++) {
                this.yDataDemandaRegistradaForaPonta.push(demanda.demandaRegistradaForaPonta);
                this.yDataDemandaRegistradaPonta.push(demanda.demandaRegistradaPonta);
            }
            arrayOfValues.forEach(item => {
                // let hora = parseInt(item.hora);

                if (item.postoTarifario === 'PONTA') {
                    this.yDataDMCRPonta.push(item.dmcr);
                } else if (item.postoTarifario === 'FORA_PONTA') {
                    this.yDataDMCRForaPonta.push(item.dmcr);
                }
            });
            this.carregaGrafico();
        }

    }

    ngOnChanges(changes: import('@angular/core').SimpleChanges): void {

        let demanda = this.dataDemandaRegistrada;
        let arrayOfValues = this.dataHourlyData;

        if (arrayOfValues && demanda) {
            this.limpaDados();

            for (let i = 0; i < 24; i++) {
                this.yDataDemandaRegistradaForaPonta.push(demanda.demandaRegistradaForaPonta);
                this.yDataDemandaRegistradaPonta.push(demanda.demandaRegistradaPonta);
            }

            arrayOfValues.forEach(item => {
                let hora = parseInt(item.hora, 10);

                if (item.postoTarifario === 'PONTA') {
                    this.yDataDMCRPonta[hora] = item.dmcr;
                } else if (item.postoTarifario === 'FORA_PONTA') {
                    this.yDataDMCRForaPonta[hora] = item.dmcr;
                }
            });
            this.carregaGrafico();
        }

    }

    carregaGrafico() {
        this.themeSubscription = this.theme.getJsTheme().subscribe(config => {
            const colors: any = config.variables;
            const echarts: any = config.variables.echarts;

            let emphasisStyle = {
                itemStyle: {
                    barBorderWidth: 1,
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowOffsetY: 0,
                    shadowColor: 'rgba(0,0,0,0.5)',
                },
            };

            this.options = {
                backgroundColor: echarts.bg,
                color: [colors.primaryLight, colors.infoLight],
                tooltip: {
                    trigger: 'axis',
                    formatter:
                        function (params) {
                            let texto = '';
                            params.forEach(dados => {
                                if (dados.seriesName.match('DMCR') !== null)
                                    texto += dados.seriesName + ': ' + (dados.data).toLocaleString('pt-br') + ' ' + '<br>';
                                else
                                    texto += dados.seriesName + ': ' + (dados.data).toLocaleString('pt-br') + ' kW ' + '<br>';
                            });
                            // adding X label
                            texto += 'Hora: ' + params[0].axisValueLabel + ':00';
                            return texto;
                        },
                    position: function (pos, params, dom, rect, size) {
                        return [0, pos[1]];
                    },

                },
                legend: {
                    data: ['DMCR Ponta', 'DMCR Fora Ponta', 'Demanda Registrada Ponta', 'Demanda Registrada Fora Ponta'],
                    type: 'scroll',
                    align: 'left',
                    textStyle: {
                        color: echarts.textColor,
                    },

                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        splitLine: { show: false },
                        data: this.xData,
                        silent: false,
                        // name: '[ Hora ]',
                        nameLocation: 'middle',
                        axisTick: {
                            alignWithLabel: true,
                        },
                        axisLine: {
                            lineStyle: {
                                color: echarts.axisLineColor,
                            },
                        },
                        axisLabel: {
                            textStyle: {
                                color: echarts.textColor,
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        name: '[ kW ]',
                        axisLine: {
                            lineStyle: {
                                color: echarts.axisLineColor,
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: echarts.splitLineColor,
                            },
                        },
                        axisLabel: {
                            textStyle: {
                                color: echarts.textColor,
                            },
                            formatter: function (params) {
                                return (params).toLocaleString('pt-br');
                            },
                        },
                    },
                ],
                series: [
                    {
                        name: 'DMCR Ponta',
                        type: 'bar',
                        data: this.yDataDMCRPonta,
                        stack: 'one',
                        emphasis: emphasisStyle,
                        animationDelay: idx => idx * 10,

                    },
                    {
                        name: 'DMCR Fora Ponta',
                        type: 'bar',
                        stack: 'one',
                        emphasis: emphasisStyle,
                        data: this.yDataDMCRForaPonta,
                        itemStyle: { color: 'green' },
                        animationDelay: idx => idx * 10 + 100,
                    },
                    {
                        name: 'Demanda Registrada Ponta',
                        type: 'line',
                        emphasis: emphasisStyle,
                        data: this.yDataDemandaRegistradaPonta,
                        itemStyle: { color: 'red' },
                        animationDelay: idx => idx * 10 + 100,
                    },
                    {
                        name: 'Demanda Registrada Fora Ponta',
                        type: 'line',
                        emphasis: emphasisStyle,
                        data: this.yDataDemandaRegistradaForaPonta,
                        itemStyle: { color: 'orange' },
                        animationDelay: idx => idx * 10 + 100,
                    },

                ],
                animationEasing: 'elasticOut',
                animationDelayUpdate: idx => idx * 5,
            };

        });
    }


    limpaDados() {
        this.xData = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
        this.yDataDMCRPonta = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.yDataDMCRForaPonta = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.yDataDemandaRegistradaPonta = []; // [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.yDataDemandaRegistradaForaPonta = []; // [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    }

}
