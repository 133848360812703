import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { ConsumoPrevistoXRelizado } from '../../../../model/report/consumo_previsto_x_realizado';

@Component({
    selector: 'ngx-echarts-line-consumo-previsto-realizado',
    template: `
    <div echarts [options]="options" class="echart"></div>
  `,
})
export class EchartsLineConsumoPrevistoXRealizadoComponent implements AfterViewInit, OnChanges {

    @Input() dadosPrevistoXRealizado: ConsumoPrevistoXRelizado[];
    @Input() mesBuscado: string;

    options: any = {};
    themeSubscription: any;
    xDataPrevistoRS;
    xDataRealizadoRS;
    xDataPrevistoKWh;
    xDataRealizadoKWh;

    mesesProximos: string[] = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];

    meses = [];

    constructor(private theme: NbThemeService) { }

    ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
        this.iniciarOsDados();
        this.calculaProximos12Meses();
        this.montaGrafico();
    }

    ngAfterViewInit() {
        this.iniciarOsDados();
        this.calculaProximos12Meses();
        this.montaGrafico();
    }

    iniciarOsDados() {
        this.xDataPrevistoRS = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.xDataRealizadoRS = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.xDataPrevistoKWh = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.xDataRealizadoKWh = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    }

    calculaProximos12Meses() {

        this.dadosPrevistoXRealizado.forEach(consumo => {

            let mes = +(consumo.data.substring(0, 2));

            this.xDataPrevistoKWh[mes - 1] = consumo.previstoKwh;
            this.xDataPrevistoRS[mes - 1] = consumo.previstoRS;

            this.xDataRealizadoKWh[mes - 1] = consumo.consumoKwh;
            this.xDataRealizadoRS[mes - 1] = consumo.consumoRS;

        });
    }




    montaGrafico() {
        this.themeSubscription = this.theme.getJsTheme().subscribe(config => {
            let colors = ['red', 'blue', 'green', 'black'];
            const echarts: any = config.variables.echarts;

            this.options = {
                backgroundColor: echarts.bg,
                //   color: [colors[2], colors[2], colors[1], colors[1]],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                    },
                    formatter:
                        function (params) {
                            let texto = '';
                            params.forEach(dados => {
                                switch (dados.seriesName) {
                                    case 'Previsto [kWh]':
                                        texto += dados.seriesName + ': ' + (dados.data).toLocaleString('pt-br') + '<br>';
                                        break;
                                    case 'Realizado [kWh]':
                                        texto += dados.seriesName + ': ' + (dados.data).toLocaleString('pt-br') + '<br>';
                                        break;
                                    case 'Previsto [R$]':
                                        texto += dados.seriesName + ': ' + (dados.data).toLocaleString('pt-br') + '<br>';
                                        break;
                                    case 'Realizado [R$]':
                                        texto += dados.seriesName + ': ' + (dados.data).toLocaleString('pt-br') + '<br>';
                                        break;

                                    default:
                                        // console.log(dados.seriesName);
                                        break;
                                }
                            });

                            texto += 'Hora: ' + params[0].axisValueLabel;

                            return texto;
                        }
                    , position: function (pos, params, dom, rect, size) {

                        if (size.viewSize[0] < 500) {
                            if (+(pos[0] > size.viewSize[0] * 0.2)) {
                                return [size.viewSize[0] * 0.2, pos[1]];
                            }
                            return [pos[0], pos[1]];
                        } else {
                            if (+(pos[0] > size.viewSize[0] * 0.7)) {
                                return [size.viewSize[0] * 0.7, pos[1]];
                            }
                            return [pos[0], pos[1]];
                        }
                    },
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                legend: {
                    left: 'left',
                    data: ['Previsto [kWh]', 'Realizado [kWh]', 'Previsto [R$]', 'Realizado [R$]'],
                    type: 'scroll',
                    textStyle: {
                        color: echarts.textColor,
                    },
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.mesesProximos,
                        axisTick: {
                            alignWithLabel: true,
                        },
                        axisLine: {
                            lineStyle: {
                                color: echarts.axisLineColor,
                            },
                        },
                        axisLabel: {
                            textStyle: {
                                color: echarts.textColor,
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: 'Valor [R$]',
                        min: 0,
                        position: 'right',
                        axisLine: {
                            lineStyle: {
                                color: colors[2],
                            },
                        },
                        axisLabel: {
                            formatter: function (params) {
                                return (params).toLocaleString('pt-br');
                            },
                        },
                    },
                    {
                        type: 'value',
                        name: 'Valor [KWh]',
                        min: 0,
                        position: 'left',
                        offset: 0,
                        axisLine: {
                            lineStyle: {
                                color: colors[1],
                            },
                        },
                        axisLabel: {
                            formatter: function (params) {
                                return (params).toLocaleString('pt-br');
                            },
                        },
                    },
                ],
                series: [
                    {
                        name: 'Previsto [kWh]',
                        type: 'line',
                        yAxisIndex: 1,
                        data: this.xDataPrevistoKWh,
                        lineStyle: {
                            type: 'dashed',
                        },
                        symbol: 'triangle',
                        color: colors[1],
                    },
                    {
                        name: 'Realizado [kWh]',
                        type: 'bar',
                        yAxisIndex: 1,
                        data: this.xDataRealizadoKWh,
                        lineStyle: {
                            type: 'dashed',
                        },
                        color: colors[1],
                    },
                    {
                        name: 'Previsto [R$]',
                        type: 'line',
                        yAxisIndex: 0,
                        data: this.xDataPrevistoRS,
                        symbol: 'triangle',
                        color: colors[2],
                    },
                    {
                        name: 'Realizado [R$]',
                        type: 'bar',
                        yAxisIndex: 0,
                        data: this.xDataRealizadoRS,
                        color: colors[2],
                    },
                ],
            };
        });
    }

}
