import * as HttpStatus from 'http-status-codes';
import { AuthService } from '../../service/auth/auth.service';
import { AlertMessage } from './../../model/alert/alert.model';

export class AlertMsg {

    public static type_error = 'error';
    public static type_warning = 'warning';
    public static type_success = 'success';
    public static type_info = 'info';
    public static type_question = 'question';
    public static alert: AlertMessage;

    public static responseControl(code: Number, msg?: string): AlertMessage {
        switch (code) {
            case HttpStatus.CREATED: {
                return { success: true, msg: (msg !== undefined) ? msg : 'Operação feita com sucesso' };
            }
            case HttpStatus.OK: {
                return { success: true, msg: (msg !== undefined) ? msg : 'Operação feita com sucesso' };
            }
            case HttpStatus.NOT_FOUND: {
                return { success: false, msg: (msg !== undefined) ? msg : '404 Not Found' };
            }
            case HttpStatus.FORBIDDEN: {
                return { success: false, msg: (msg !== undefined) ? msg : '403 Forbidden' };
            }
            case HttpStatus.UNAUTHORIZED: {
                return { success: false, msg: (msg !== undefined) ? msg : '401 Unauthorized' };
            }
            case HttpStatus.NOT_ACCEPTABLE: {
                return { success: false, msg: (msg !== undefined) ? msg : '406 Not Acceptable' };
            }
            case HttpStatus.BAD_REQUEST: {
                return { success: false, msg: (msg !== undefined) ? msg : '400 Bad Request' };
            }
            case 0: {
                return { success: false, msg: (msg !== undefined) ? msg : 'Erro' };
            }
            default: {
                return { success: false, msg: '' };
            }

        }
    }

    public static toastResponse(responseError, func) {

        // Caso abrar tela home sem token
        if (responseError.error.error === 'Unauthorized') {
            sessionStorage.removeItem('tokenTelefonica');
            AuthService.verifyLogged();
            func.showToast('danger', 'Não autorizado');
            return;
        }

        if (HttpStatus.FORBIDDEN === responseError.status) {
            func.showToast('danger', 'Sem permissão para executar a ação');
            return;
        }

        if (responseError.error.status)
            func.showToast('danger', responseError.error.status);
        else
            func.showToast('danger', 'Problemas ao conectar ao servidor');
    }
}
