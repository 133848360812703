import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-dialog-simulator-prompt',
  templateUrl: 'dialog-simulator-prompt.component.html',
  styleUrls: ['dialog-simulator-prompt.component.scss'],
})
export class DialogSimulatorPromptComponent {

  constructor(protected ref: NbDialogRef<DialogSimulatorPromptComponent>) { }

  cancel() {
    this.ref.close(0);
  }


  submit() {
    this.ref.close(1);

  }
}
