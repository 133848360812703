import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceMaster } from './service.master';

// @ todo VERIFICAR A NECESSIDADE DESTE ARQUIVO, este conteudo poderia ser adiciondo a HourlyDataService
@Injectable()
export class EconomyPotentialService {


    constructor(private http: HttpClient) {
    }

    getEconomyPotentialTodayByEquipamentId(id: string) {
        return this.http.get(ServiceMaster.baseURL + '/hourly_data/economy?equipamentId=' + id,
            { headers: ServiceMaster.getHeaders(), observe: 'response' });
    }

}
