import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { Consumo } from '../../../../model/consumo.model';

@Component({
    selector: 'ngx-echarts-bar-animation-consumo',
    template: `
    <div echarts [options]="options" class="echart"></div>
  `,
})
export class EchartsBarAnimationConsumoComponent implements AfterViewInit, OnChanges {
    @Input() dataConsumoAnual: Consumo[];
    @Input() dataConsumoMensal: Consumo[];
    @Input() dataConsumoDiario: Consumo[];
    @Input() date: Date;
    @Input() periodo;

    options: any = {};
    themeSubscription: any;
    equipamentID = '';

    arrayOfValues = [];
    data1 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    data2 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    mesesUltimos: string[] = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ', 'JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];
    meses: string[] = ['', '', '', '', '', '', '', '', '', '', '', ''];

    labelX: string[] = ['Mês', 'Dia', 'Hora'];
    eixoX: any = ['', '', '', '', '', '', '', '', '', '', '', ''];

    constructor(private theme: NbThemeService) {
    }

    ngAfterViewInit() {
        if (this.dataConsumoAnual) {
            this.limpaDadosAnual();
            this.arrayOfValues = this.dataConsumoAnual;
            this.formatarDadosAnual(this.arrayOfValues);
            this.periodo = 0;
            this.montaGrafico();
        } else if (this.dataConsumoMensal) {
            this.limpaDadosMensal();
            this.arrayOfValues = this.dataConsumoAnual;
            this.formatarDadosMensal(this.arrayOfValues);
            this.periodo = 1;
            this.montaGrafico();
        } else if (this.dataConsumoDiario) {
            this.limpaDadosDiario();
            this.arrayOfValues = this.dataConsumoAnual;
            this.formatarDadosDiario(this.arrayOfValues);
            this.periodo = 2;
            this.montaGrafico();
        } else {
            this.periodo = 0;
        }
    }

    ngOnChanges(changes: import('@angular/core').SimpleChanges): void {

        if (this.dataConsumoAnual) {
            this.limpaDadosAnual();
            this.arrayOfValues = this.dataConsumoAnual;
            this.formatarDadosAnual(this.arrayOfValues);
            this.periodo = 0;
            this.montaGrafico();
        } else if (this.dataConsumoMensal) {
            this.limpaDadosMensal();
            this.arrayOfValues = this.dataConsumoMensal;
            this.formatarDadosMensal(this.arrayOfValues);
            this.periodo = 1;
            this.montaGrafico();
        } else if (this.dataConsumoDiario) {
            this.limpaDadosDiario();
            this.arrayOfValues = this.dataConsumoDiario;
            this.formatarDadosDiario(this.arrayOfValues);
            this.periodo = 2;
            this.montaGrafico();
        } else {
            this.periodo = 0;
        }
    }

    formatarDadosAnual(dados: Consumo[]) {
        let data = (this.date) ? this.date : (new Date());

        if (dados.length === 0) return;

        let arrayAuxPonta = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let arrayAuxForaPonta = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        dados.forEach(dadoMensal => {
            if (dadoMensal.ano === data.getFullYear()) {
                dadoMensal.consumo.forEach(item => {
                    if (item.postoTarifario === 'PONTA')
                        arrayAuxPonta[dadoMensal.mes + 11] = item.pulsosEnergiaAtiva;
                    else if (item.postoTarifario === 'FORA_PONTA')
                        arrayAuxForaPonta[dadoMensal.mes + 11] = item.pulsosEnergiaAtiva;
                });
            } else {
                dadoMensal.consumo.forEach(item => {
                    if (item.postoTarifario === 'PONTA')
                        arrayAuxPonta[dadoMensal.mes - 1] = item.pulsosEnergiaAtiva;
                    else if (item.postoTarifario === 'FORA_PONTA')
                        arrayAuxForaPonta[dadoMensal.mes - 1] = item.pulsosEnergiaAtiva;
                });
            }
        });

        let mes = data.getMonth();
        for (let i = 11; i >= 0; i--) {
            this.meses[i] = this.mesesUltimos[mes + i + 1];
            this.data1[i] = arrayAuxForaPonta[mes + i + 1];
            this.data2[i] = arrayAuxPonta[mes + i + 1];
        }

        this.eixoX = this.meses;
    }

    formatarDadosMensal(dados: Consumo[]) {
        let data = (this.date) ? this.date : (new Date());

        let lastDay = (new Date(data.getFullYear(), data.getMonth() + 1, 0)).getDate();

        if (dados.length === 0) return;

        let arrayAuxPonta = [];
        let arrayAuxForaPonta = [];
        this.eixoX = [];
        for (let i = 1; i <= lastDay; i++) {
            arrayAuxPonta.push(0);
            arrayAuxForaPonta.push(0);
            this.eixoX.push(`${i}`);
        }

        dados.forEach(dataDiario => {
            dataDiario.consumo.forEach(item => {
                if (item.postoTarifario === 'PONTA')
                    arrayAuxPonta[dataDiario.dia - 1] = item.pulsosEnergiaAtiva;
                else if (item.postoTarifario === 'FORA_PONTA')
                    arrayAuxForaPonta[dataDiario.dia - 1] = item.pulsosEnergiaAtiva;
            });
        });

        this.data1 = arrayAuxForaPonta;
        this.data2 = arrayAuxPonta;
    }

    formatarDadosDiario(dados: Consumo[]) {
        // let data = (this.date) ? this.date : (new Date());

        if (dados.length === 0 || dados === undefined) return;

        let arrayAuxPonta = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let arrayAuxForaPonta = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.eixoX = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

        dados.forEach(dataHorario => {
            dataHorario.consumo.forEach(item => {
                if (item.postoTarifario === 'PONTA')
                    arrayAuxPonta[dataHorario.hora] = item.pulsosEnergiaAtiva;
                else if (item.postoTarifario === 'FORA_PONTA')
                    arrayAuxForaPonta[dataHorario.hora] = item.pulsosEnergiaAtiva;
            });
        });

        this.data1 = arrayAuxForaPonta;
        this.data2 = arrayAuxPonta;
    }

    limpaDadosAnual() {
        this.arrayOfValues = [];
        this.data1 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.data2 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    }

    limpaDadosMensal() {
        this.arrayOfValues = [];
        this.data1 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.data2 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    }

    limpaDadosDiario() {
        this.arrayOfValues = [];
        this.data1 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.data2 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    }

    montaGrafico() {
        this.themeSubscription = this.theme.getJsTheme().subscribe(config => {
            const xAxisData = this.eixoX;

            const colors: any = config.variables;
            const echarts: any = config.variables.echarts;

            let emphasisStyle = {
                itemStyle: {
                    barBorderWidth: 1,
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowOffsetY: 0,
                    shadowColor: 'rgba(0,0,0,0.5)',
                },
            };

            this.options = {
                backgroundColor: echarts.bg,
                color: [colors.primaryLight, colors.infoLight],
                tooltip: {
                    trigger: 'axis',
                    formatter:
                        (params) => {
                            let texto = '';
                            params.forEach(dados => {

                                switch (dados.seriesName) {
                                    case 'Fora Ponta':
                                        texto += dados.seriesName + ': ' + (dados.data).toLocaleString('pt-br') + ' kWh ' + '<br>';
                                        break;
                                    case 'Ponta':
                                        texto += dados.seriesName + ': ' + (dados.data).toLocaleString('pt-br') + ' kWh ' + '<br>';
                                        break;
                                    default:
                                        break;
                                }
                            });

                            // adding X label
                            texto += `${this.labelX[this.periodo]}: ${params[0].axisValueLabel}`;

                            return texto;
                        }
                    , position: function (pos, params, dom, rect, size) {

                        if (size.viewSize[0] < 500) {
                            if (+(pos[0] > size.viewSize[0] * 0.2)) {
                                return [size.viewSize[0] * 0.2, pos[1]];
                            }
                            return [pos[0], pos[1]];
                        } else {
                            if (+(pos[0] > size.viewSize[0] * 0.7)) {
                                return [size.viewSize[0] * 0.7, pos[1]];
                            }
                            return [pos[0], pos[1]];
                        }
                    },

                },
                legend: {
                    data: ['Ponta', 'Fora Ponta'],
                    align: 'left',
                    type: 'scroll',
                    textStyle: {
                        color: echarts.textColor,
                    },

                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        splitLine: { show: false },
                        data: xAxisData,
                        silent: false,
                        //name: '[' + this.labelX[this.periodo] + ']',
                        axisTick: {
                            alignWithLabel: true,
                        },
                        axisLine: {
                            lineStyle: {
                                color: echarts.axisLineColor,
                            },
                        },
                        axisLabel: {
                            textStyle: {
                                color: echarts.textColor,
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        name: '[ kWh ]',
                        type: 'value',
                        axisLine: {
                            lineStyle: {
                                color: echarts.axisLineColor,
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: echarts.splitLineColor,
                            },
                        },
                        axisLabel: {
                            margin: 6,
                            textStyle: {
                                color: echarts.textColor,
                            },
                            formatter: function (params) {
                                return (params).toLocaleString('pt-br');
                            },
                        },

                    },
                ],
                series: [
                    {
                        name: 'Fora Ponta',
                        type: 'bar',
                        data: this.data1,
                        stack: 'two',
                        emphasis: emphasisStyle,
                        itemStyle: { color: 'green' },
                        animationDelay: idx => idx * 10,
                    },
                    {
                        name: 'Ponta',
                        type: 'bar',
                        stack: 'two',
                        emphasis: emphasisStyle,
                        data: this.data2,
                        //itemStyle: { color: 'red' },
                        animationDelay: idx => idx * 10 + 100,
                    },

                ],
                animationEasing: 'elasticOut',
                animationDelayUpdate: idx => idx * 5,
            };

        });
    }
}
