import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import { NbThemeService } from '@nebular/theme';


interface TableObject {
    date: Date;
    value1: number;
    value2: number;
    diff: number;
}

@Component({
    selector: 'ngx-echarts-bar-report-comparison-sites',
    template: `
    <div echarts [options]="options" class="echart"></div>
  `,
})
export class EchartsBarReportComparisonSitesComponent implements AfterViewInit, OnChanges {

    @Input() data: TableObject;
    @Input() unit1: string = '1';
    @Input() unit2: string = '2';
    @Input() grandeza: string;

    options: any = {};
    themeSubscription: any;

    dataCenterData = [];
    callCenterData = [];
    tecnicoData = [];
    mistoData = [];
    administrativoData = [];

    constructor(private theme: NbThemeService) { }

    ngAfterViewInit() {
        this.loadDataToChart();
    }

    // ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
        this.loadDataToChart();
        // console.log();
    }

    loadDataToChart() {

        if (this.data)
            this.themeSubscription = this.theme.getJsTheme().subscribe(config => {
                // const xAxisData = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
                const echarts: any = config.variables.echarts;

                this.options = {
                    backgroundColor: echarts.bg,
                    color: ['blue', 'orange'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                        },
                        formatter:
                            (params) => {
                                let texto = '';
                                texto += `${this.unit1}: ${(params[0].data.value1).toLocaleString('pt-br')} <br>`;
                                texto += `${this.unit2}: ${(params[0].data.value2).toLocaleString('pt-br')} <br>`;
                                // adding X label
                                texto += '<br>' + 'Dia: ' + params[0].axisValueLabel;
                                return texto;
                            },
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true,
                    },
                    /* toolbox: {
                    feature: {
                        dataView: { show: true, readOnly: false },
                        restore: { show: true },
                        saveAsImage: { show: true },
                    }
                    }, */

                    legend: {
                        data: [this.unit1, this.unit2],
                        align: 'left',
                        type: 'scroll',
                        textStyle: {
                            color: echarts.textColor,
                        },
                        margin: 20,
                    },
                    xAxis: [
                        {
                            type: 'category',
                            splitLine: { show: false },
                            gridIndex: 0,
                            silent: false,
                            axisTick: {
                                alignWithLabel: true,
                            },
                            axisLine: {
                                lineStyle: {
                                    color: echarts.axisLineColor,
                                },
                            },
                            axisLabel: {
                                textStyle: {
                                    color: echarts.textColor,
                                },
                                margin: 15,
                            },
                        },
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            min: 0,
                            position: 'left',
                            axisLine: {
                                lineStyle: {
                                    color: 'blue',
                                },
                            },
                            axisLabel: {
                                formatter: function (params) {
                                    return (params).toLocaleString('pt-br');
                                },
                            },
                        },
                        {
                            type: 'value',
                            name: this.grandeza,
                            min: 0,
                            position: 'right',
                            axisLine: {
                                lineStyle: {
                                    color: 'orange',
                                },
                            },
                            axisLabel: {
                                formatter: function (params) {
                                    return (params).toLocaleString('pt-br');
                                },
                            },
                        },
                    ],
                    dataset: {
                        dimensions: ['date', 'value1', 'value2', 'diff'],
                        source: this.data,
                    },
                    series: [
                        { type: 'line', seriesLayoutBy: 'row' },
                        { type: 'line', seriesLayoutBy: 'row' },
                    ],
                    animationEasing: 'elasticOut',
                    animationDelayUpdate: idx => idx * 5,
                };

            });
    }


}
