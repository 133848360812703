import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { sha256 } from 'js-sha256';
import { AlertMessage } from '../../model/alert/alert.model';
import { LoginRequest } from '../../model/auth/login_request.model';
import { LoginResponse } from '../../model/auth/login_response.model';
import { User } from '../../model/user/user.model';
import { AuthService } from '../../service/auth/auth.service';
import { AlertMsg } from '../../util/alert/alert.util';
import { UserService } from './../../service/user.service';

@Component({
    selector: 'ngx-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

    loginResponse: LoginResponse;
    user: LoginRequest = { password: '', username: '' };
    alertMessage: AlertMessage = { success: false, msg: '' };
    captchaResolved = false;
    captchaToken: string | null = null;

    constructor(private router: Router, private authService: AuthService, private userService: UserService) {
        localStorage.clear();
        sessionStorage.clear();
    }

    onCaptchaResolved(captchaResponse: string) {
        // console.log(`Resolved captcha with response: ${captchaResponse}`);
        this.captchaResolved = !!captchaResponse;
        this.captchaToken = captchaResponse;
    }

    login() {
        if (!this.captchaResolved) {
            this.alertMessage = { success: false, msg: 'Por favor, resolva o CAPTCHA' };
            return;
        }

        // Inclui o token do CAPTCHA na requisição de login
        const loginPayload = {
            username: this.user.username,
            password: this.user.password,
            captchaToken: this.captchaToken,
        };

        this.authService.login(loginPayload).subscribe(dados => {
            this.alertMessage = AlertMsg.responseControl(dados.status);
            this.loginResponse = <LoginResponse>dados.body;

            this.authService.setSecureToken(this.loginResponse.accessToken);
            this.authService.setUsername(this.user.username);

            this.userService.getMe().subscribe(data => {
                const user = <User>data.body;
                sessionStorage.setItem('token2', sha256(`${user.role}`));
                sessionStorage.setItem('accountID', `${user.accountID}`);

                setTimeout(() => {
                    this.router.navigate(['/pages/home']);
                }, 1000);
            }, error => {
                this.alertMessage = AlertMsg.responseControl(error.status, 'Erro ao carregar permissões');
            });

        }, error => {
            this.alertMessage = AlertMsg.responseControl(error.status, 'Erro ao efetuar login');
        });
    }

    ngOnInit() {
    }

    onChange() {
        this.alertMessage = AlertMsg.responseControl(-1, '');
    }

}
