import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import { NbThemeService } from '@nebular/theme';

@Component({
    selector: 'ngx-echarts-bar-history-consumption-rs',
    template: `
    <div echarts [options]="options" class="echart"></div>
  `,
})
export class EchartsBarHistoryConsumptionRsComponent implements AfterViewInit, OnChanges {

    @Input() dataInput: Array<any>;
    @Input() year1: number;
    @Input() year2: number;

    options: any = {};
    themeSubscription: any;
    xDataAnterior = [];
    xDataAtual = [];
    xDiff = [];

    mesesProximos: string[] = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];

    meses = [];

    constructor(private theme: NbThemeService) {
        this.year1 = 2019;
        this.year2 = 2020;
    }

    ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
        this.iniciarOsDados();
        this.formatData();
        this.montaGrafico();
    }


    ngAfterViewInit() {
        this.iniciarOsDados();
        this.formatData();
        this.montaGrafico();
    }

    formatData() {
        if (this.dataInput && this.dataInput.length > 0) {
            if (this.dataInput['0'][`${this.year1}`] && this.dataInput['1'][`${this.year2}`]) {
                this.xDataAnterior = this.dataInput['0'][`${this.year1}`]['rs'];
                this.xDataAtual = this.dataInput['1'][`${this.year2}`]['rs'];

                for (let i = 0; i < 12; i++) {
                    this.xDiff[i] = this.xDataAtual[i] - this.xDataAnterior[i];
                }
            }
        }
    }

    iniciarOsDados() {
        this.xDataAnterior = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.xDataAtual = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.xDiff = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        // this.xDataAnterior = [0, 1, 2, 3, 4, 5, 6, 5, 4, 3, 2, 1];
        // this.xDataAtual = [6, 5, 4, 3, 2, 1, 0, 1, 2, 3, 4, 5];
    }
    montaGrafico() {

        this.themeSubscription = this.theme.getJsTheme().subscribe(config => {

            let colors = ['orange'];
            let legends = [`Diferença`];

            const echarts: any = config.variables.echarts;

            this.options = {
                backgroundColor: echarts.bg,
                color: colors,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                    },
                    formatter: (params) => {
                        const texto = `Diferença: R$ ${(params[0].value).toLocaleString('pt-br')}`;
                        return texto;
                    }
                    , position: function (pos, params, dom, rect, size) {

                        if (size.viewSize[0] < 500) {
                            if (+(pos[0] > size.viewSize[0] * 0.2)) {
                                return [size.viewSize[0] * 0.2, pos[1]];
                            }
                            return [pos[0], pos[1]];
                        } else {
                            if (+(pos[0] > size.viewSize[0] * 0.7)) {
                                return [size.viewSize[0] * 0.7, pos[1]];
                            }
                            return [pos[0], pos[1]];
                        }
                    },
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                legend: {
                    data: legends,
                    type: 'scroll',
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.mesesProximos,
                        axisTick: {
                            alignWithLabel: true,
                        },
                        axisLine: {
                            lineStyle: {
                                color: echarts.axisLineColor,
                            },
                        },
                        axisLabel: {
                            textStyle: {
                                color: echarts.textColor,
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: 'Valor [R$]',
                        // min: 0,
                        position: 'left',
                        axisLabel: {
                            formatter: function (params) {
                                return (params).toLocaleString('pt-br');
                            },
                        },
                    },
                ],
                series: [
                    {
                        name: legends[0],
                        type: 'bar',
                        barGap: 0,
                        data: this.xDiff,
                    },
                ],
            };
        });
    }

    // montaGrafico() {

    //     this.themeSubscription = this.theme.getJsTheme().subscribe(config => {

    //         let colors = ['blue', 'green'];
    //         let legends = [`Consumo ${this.year1} [R$]`, `Consumo ${this.year2} [R$]`]

    //         const echarts: any = config.variables.echarts;

    //         this.options = {
    //             backgroundColor: echarts.bg,
    //             color: colors,
    //             tooltip: {
    //                 trigger: 'axis',
    //                 axisPointer: {
    //                     type: 'cross'
    //                 }
    //             },
    //             grid: {
    //                 right: '20%'
    //             },
    //             legend: {
    //                 data: legends,
    //             },
    //             xAxis: [
    //                 {
    //                     type: 'category',
    //                     data: this.mesesProximos,
    //                     axisTick: {
    //                         alignWithLabel: true,
    //                     },
    //                     axisLine: {
    //                         lineStyle: {
    //                             color: echarts.axisLineColor,
    //                         },
    //                     },
    //                     axisLabel: {
    //                         textStyle: {
    //                             color: echarts.textColor,
    //                         },
    //                     },
    //                 },
    //             ],
    //             yAxis: [
    //                 {
    //                     type: 'value',
    //                     name: 'Valor [R$]',
    //                     // min: 0,
    //                     position: 'left',
    //                     axisLabel: {
    //                         formatter: function (params) {
    //                             return (params).toLocaleString('pt-br')
    //                         }
    //                     }
    //                 },
    //             ],
    //             series: [
    //                 {
    //                     name: legends[0],
    //                     type: 'bar',
    //                     barGap: 0,
    //                     data: this.xDataAnterior,
    //                 },
    //                 {
    //                     name: legends[1],
    //                     type: 'bar',
    //                     data: this.xDataAtual,
    //                 },
    //             ],
    //         };
    //     });
    // }

}
